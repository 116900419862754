
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from "./state/store";
import { vMaska } from "maska";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import CKEditor from '@ckeditor/ckeditor5-vue';
import BootstrapVueNext from 'bootstrap-vue-next';

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import "@vueform/multiselect/themes/default.css"
import './assets/scss/app.scss';

import 'vue3-toastify/dist/index.css';

// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();


// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);
var baseUrl = (process.env.VUE_APP_ENV == 'local' ? 'http://localhost:3000' : window.location.origin);
store.commit('setApiUrl', `${baseUrl}/api`);
store.commit('setVersion', process.env.VUE_APP_VERSION);
store.commit('setEnv', process.env.VUE_APP_ENV);
store.commit('setVersion', process.env.VUE_APP_VERSION);

import VueMask from '@devindex/vue-mask';

const logged = !(!localStorage.getItem('trp-login'));
if(logged){

    const jwtPayload = UtilMixing.parseJwt((localStorage.getItem('trp-login')));
    if(jwtPayload){
        const refreshToken = JSON.parse(localStorage.getItem('trp-login')).refreshToken;
        const jwt = JSON.parse(localStorage.getItem('trp-login')).jwt;
        const sessionId = JSON.parse(localStorage.getItem('trp-login')).sessionId;
        store.commit('setLogin', { jwt, refreshToken, sessionId });
        store.commit('setUserRole', jwtPayload.role);
    }
} else {
    store.commit('setLoaded', true);
}
import "primeflex/primeflex.css";
import 'primevue/resources/themes/lara-light-teal/theme.css'
import "primevue/resources/primevue.min.css";
import PrimeVue from 'primevue/config';
import UtilMixing from './mixings/UtilMixing';
import { Money3Component } from 'v-money3'
var app = createApp(App);
import AutoComplete from 'primevue/autocomplete';
app.component('AutoComplete', AutoComplete);
app.use(store)
app.use(router)
app.component("money3", Money3Component)
app.use(PrimeVue)
app.use(VueApexCharts)
app.use(BootstrapVueNext)
app.use(i18n)
app.directive("maska", vMaska)
app.use(CKEditor)
app.use(VueMask)
app.use(vClickOutside)

import  currency  from 'currency.js'
app.config.globalProperties.$filters = {

    companyAllowFutureInvoice(){
        try{
            return store.state.session.companyAllowFutureInvoice == true;
        } catch{
            return false;
        }
    }, 
    allowFutureInvoice(){
        try{
            return store.state.session.allowFutureInvoice == true;
        } catch{
            return false;
        }
    },  
    isProd(){
        try{
            return window.location.origin.includes('app.transferporto')
        } catch{
            return false;
        }
    },  

    isFinancial(){
        try{
            return store.state.session.role == 'FINANCIAL';
        } catch{
            return false;
        }
    },
    isUser(){
        try{
            return store.state.session.role == 'USER';
        } catch{
            return false;
        }
    },

    isAdmin(){
        try{
            return store.state.session.role == 'ADMIN';
        } catch{
            return false;
        }
    },


    stringToMoney(amount) {
        try {
            var amountParsed =  parseFloat(amount.replace(/R\$/g, "").replace(/\./, "").replace(/,/g, ".").trim());
            return isNaN(amountParsed) ? 0 : amountParsed;
        } catch {
            return 0;
        }
    },
    
    toCurrency(value) {
        var valor = value;
  
        if(valor == null) 
          valor = 0;
      
        if (typeof valor !== "number") {
            value = parseFloat(value);
            if(isNaN(value)) 
              return value;
        }
        const REAL = value => currency(value, { symbol: 'R$ ', decimal: ',', separator: '.' });
        return REAL(value).format();
    },
    
    toPercentage(value) {
        var valor = value;
  
        valor = parseFloat(valor || 0);
        if(valor == null || valor == undefined) 
          valor = 0;

        return `${valor}%`;
    },
    
    formatDocument(value) {
        if(UtilMixing.isCnpjValid(value)){
             return UtilMixing.formatCnpj(value);
        } else if (UtilMixing.isCpfValid(value)){
            return UtilMixing.formatCpf(value);
        }  else{
            return value;
        }
       
    },
}


app.mount('#app');