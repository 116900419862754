import store from "@/state/store";

export default [
    // authe

    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },

    {
        path: "/register",
        name: "Register",
        component: () => import("../views/account/register.vue"),
        meta: {
            title: "Register",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: {
            title: "Forgot Password",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },

    {
        path: "/logout",
        name: "logout",
        meta: { title: "Logout", authRequired: true, beforeResolve(routeTo, routeFrom, next) { localStorage.clear(); sessionStorage.clear(); next(); }, },
        component: () => import("../views/account/logout.vue"),
    },
    {
        path: "/public/register",
        name: "Public Register",
        meta: { title: "Novo Cadastro", authRequired: false },
        component: () => import("../views-transferporto/account/publicRegister.vue"),
    },
    {
        path: "/public/recoveryPassword/:token",
        name: "Public recoveryPassword",
        meta: { title: "Login", authRequired: false },
        component: () => import("../views-transferporto/account/recoveryPassword.vue"),
    },
    {
        path: "/public/forgotAccess",
        name: "Public forgotAccess",
        meta: { title: "Login", authRequired: false },
        component: () => import("../views-transferporto/account/forgotAccess.vue"),
    },

    {
        path: "/public/login",
        name: "Public Login",
        meta: { title: "Login", authRequired: false },
        component: () => import("../views-transferporto/account/login.vue"),
    },
    {
        path: "/internal/reports",
        name: "Reports Create",
        meta: { title: "Reports Create", authRequired: false },
        component: () => import("../views-transferporto/reports/reportCreate.vue"),
    },
    {
        path: "/admin/login",
        name: "Admin Login",
        meta: { title: "Login Admin", authRequired: false },
        component: () => import("../views-transferporto/account/loginAdmin.vue"),
    },
    {
        path: "/financial/login",
        name: "Financeiro Login",
        meta: { title: "Login Admin", authRequired: false },
        component: () => import("../views-transferporto/account/loginFinancial.vue"),
    },
    {
        path: "/internal/dashboard",
        name: "Admin Dashboard 2",
        meta: { title: "Boas Vindas", authRequired: true },
        component: function(){
            return import("../views-transferporto/dashboard/welcome.vue");
        },
    },
    {
        path: "/internal/seasons",
        name: "Listar Temporadas",
        meta: { title: "listar Temporadas", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/season/seasonList.vue"),
    }, 
    {
        path: "/internal/users",
        name: "Usuários",
        meta: { title: "listar Usuarios", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/user/userList.vue"),
    }, 
    {
        path: "/internal/bookings",
        name: "Minhas Reservas",
        meta: { title: "listar Reservas", authRequired: true },
        component: () => import("../views-transferporto/booking/bookingList.vue"),
    }, 
    {
        path: "/internal/payments",
        name: "Meus Pagamentos",
        meta: { title: "listar Pagamentos", authRequired: true },
        component: () => import("../views-transferporto/payment/paymentList.vue"),
    }, 
    {
        path: "/internal/bookings/pendingPayment",
        name: "Minhas Reservas - pendente",
        meta: { title: "listar Reservas - pendente", authRequired: true },
        component: () => import("../views-transferporto/booking/bookingListPendingPayment.vue"),
    }, 
    {
        path: "/internal/bookings/invoiced",
        name: "Minhas Reservas - faturadas",
        meta: { title: "listar Reservas - faturadas", authRequired: true },
        component: () => import("../views-transferporto/booking/bookingListInvoiced.vue"),
    }, 
    {
        path: "/internal/carriers",
        name: "Listar Transportadoras",
        meta: { title: "listar Transportadoras", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/carrier/carrierList.vue"),
    },  


    {
        path: "/internal/booking/new/",
        name: "Nova Reserva - Agencia",
        meta: { title: "Emissão Reserva", authRequired: true },
        component: () => import("../views-transferporto/booking/bookingCreate.vue"),
    },  
    {
        path: "/internal/booking/:id/edit",
        name: "Editar Reserva - Agencia",
        meta: { title: "Editar Reserva", authRequired: true },
        component: () => import("../views-transferporto/booking/bookingEdit.vue"),
    },
    {
        path: "/internal/booking/:id",
        name: "Visualizar Reserva - Agencia",
        meta: { title: "Visualizar Reserva", authRequired: true },
        component: () => import("../views-transferporto/booking/bookingView.vue"),
    },
    {
        path: "/internal/booking/:id/pay",
        name: "Pagamento de Reserva",
        meta: { title: "Pagamento de Reserva", authRequired: true },
        component: () => import("../views-transferporto/payment/paymentBookingCreate.vue"),
    },
    {
        path: "/internal/payment/new/",
        name: "Pagamento de Reserva 2",
        meta: { title: "Pagamento de Reserva 2", authRequired: true },
        component: () => import("../views-transferporto/payment/paymentBookingCreate.vue"),
    },
    {
        path: "/internal/companies",
        name: "Listar Agências",
        meta: { title: "listar Agências", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/company/companyList.vue"),
    },    
    {
        path: "/internal/guides",
        name: "Listar Guias",
        meta: { title: "listar Guias", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/guide/guideList.vue"),
    }, 
        {
        path: "/internal/ships",
        name: "Listar Navios",
        meta: { title: "listar Navios", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/ship/shipList.vue"),
    },
    {
        path: "/internal/company/:id",
        name: "Ver Agência",
        meta: { title: "Ver Agência", authRequired: true },
        component: () => import("../views-transferporto/company/companyView.vue"),
    },
    {
        path: "/internal/priceList",
        name: "Ver Tarifario",
        meta: { title: "Ver Tarifario", authRequired: true },
        component: () => import("../views-transferporto/priceList/priceListView.vue"),
    },
    {
        path: "/internal/gallery",
        name: "Ver Galeria",
        meta: { title: "Ver Galeria", authRequired: true },
        component: () => import("../views-transferporto/gallery/galleryView.vue"),
    },
    {
        path: "/internal/myCompany",
        name: "Minha Agencia",
        params : { id : '123'},
        meta: { title: "Ver Agência", authRequired: true },
        component: () => import("../views-transferporto/company/companyView.vue"),
    },
    {
        path: "/internal/carrier/:id",
        name: "Ver Transportadora",
        meta: { title: "Ver Transportadora", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/carrier/carrierView.vue"),
    },
    {
        path: "/internal/travelListCreate",
        name: "Criar Lista Embarque",
        meta: { title: "Criar Lista Embarque", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/travelListCreate/travelListCreate.vue"),
    },
        {
        path: "/internal/season/:id",
        name: "Ver Temporada",
        meta: { title: "Ver Temporada", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/season/seasonView.vue"),
    },
    {
        path: "/internal/boardings",
        name: "Listar de Embarques",
        meta: { title: "Listar de Embarques", authRequired: true, roles :['ADMIN'] },
        component: () => import("../views-transferporto/boarding/boardingList.vue"),
    }, 



    {
        path: '/internal/:pathMatch(.*)*',
        name: "Pagina nao encontrada",
        meta: { title: "Não encontrado", authRequired: false },
        component: () => import("../views-transferporto/404.vue"),
    },
    {
        path: "/public/booking/:hash",
        name: "Public View Booking",
        meta: { title: "Ver reserva", authRequired: false },
        component: () => import("../views-transferporto/booking/bookingViewPublic.vue"),
    },
     {
        path: "/public/booking/:hash/pay",
        name: "Public View Booking to Pauy",
        meta: { title: "Pagar reserva", authRequired: false },
        component: () => import("../views-transferporto/booking/bookingPayPublic.vue"),
    },
     {
        path: "/public/payment/:id",
        name: "Public View Payment",
        meta: { title: "Detalhes do Pagamento Pagamento", authRequired: false },
        component: () => import("../views-transferporto/booking/bookingPublicPayment.vue"),
    },
    {
        path: "/public/company-finalize/:id",
        name: "Public Finalize Company",
        meta: { title: "Finalizar Cadastro", authRequired: false },
        component: () => import("../views-transferporto/account/publicActivate.vue"),
    },
    {
        path: "/public/user-finalize/:id/:idUser",
        name: "Public Finalize",
        meta: { title: "Finalizar Cadastro", authRequired: false },
        component: () => import("../views-transferporto/user/publicActivate.vue"),
    },
    {
        path: '/',
        name: "Pagina login",
        meta: { title: "Home", authRequired: false },
        component: () =>  import("../views-transferporto/account/login.vue"),
    },
    {
        path: '/internal/403',
        name: "Acesso negado",
        meta: { title: "Acesso negado", authRequired: false },
        component: () => import("../views-transferporto/403.vue"),
    },
    {
        path: '/:pathMatch(.*)*',
        name: "Pagina nao encontrada",
        meta: { title: "Não encontrado", authRequired: false },
        component: () => import("../views-transferporto/404.vue"),
    },
    // Dashboards
  
];